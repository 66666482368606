@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;600&display=swap);
* {
  margin: 0;
  padding: 0;
  font-size: inherit;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  margin: 0;
  background-color: #f5f4ee;
  padding: 16px;
}

body,
select {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: center;
}

h3 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 26px;
  text-align: center;
}

p {
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
}

a {
  font-size: 14px;
  letter-spacing: 2%;
  text-decoration: none;
}

/* Prevent scrolling on <body> while modal is open */
.ReactModal__Body--open {
  overflow-y: hidden;
}

